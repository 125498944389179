import { Member } from '@/lib/members/member.ts';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert.tsx';
import { AlertTriangle, Info } from 'lucide-react';

export type MemberStatusAlertProps = {
  member: Member;
};

export default function MemberStatusAlert({ member }: MemberStatusAlertProps) {
  let memberStatus = member.memberStatus;

  // If the member status is 'Active' but the payment status is not 'Pago', the member is 'Pending'
  if (memberStatus === 'Active' && member.paymentStatus !== 'Pago') {
    memberStatus = 'Inactive';
  }

  switch (memberStatus) {
    case 'Active':
      return null;
    case 'Inactive':
      return (
        <Alert className="border-yellow-600/50 bg-yellow-600/10 text-yellow-600 dark:border-yellow-600 [&>svg]:text-yellow-600 [&>svg~*]:pl-9">
          <AlertTriangle className="h-6 w-6" />
          <AlertTitle>¡Atención!</AlertTitle>
          <AlertDescription>
            Tu cuenta está inactiva debido a un pago pendiente.
            <br />
            Por favor, llama a Movieclub para más información.
          </AlertDescription>
        </Alert>
      );
    case 'Pending':
      return (
        <Alert className="border-blue-500/50 bg-blue-500/10 text-blue-500 dark:border-blue-500 [&>svg]:text-blue-500 [&>svg~*]:pl-9">
          <Info className="h-6 w-6" />
          <AlertTitle>¡Atención!</AlertTitle>
          <AlertDescription>
            Estamos verificando tus datos. Tu cuenta se activará en breves momentos.
            <br />
            ¡Gracias por tu paciencia!
          </AlertDescription>
        </Alert>
      );
    case 'Fraud':
      return (
        <Alert className="border-yellow-600/50 bg-yellow-600/10 text-yellow-600 dark:border-yellow-600 [&>svg]:text-yellow-600 [&>svg~*]:pl-9">
          <AlertTriangle className="h-6 w-6" />
          <AlertTitle>¡Atención!</AlertTitle>
          <AlertDescription>
            Es necesario que te comuniques con nosotros para resolver un problema con tu cuenta.
            <br />
            Por favor, llama a Movieclub para más información.
          </AlertDescription>
        </Alert>
      );
    default:
      return null;
  }
}
