import { AxiosResponse } from 'axios';
import axiosInstance from '@/lib/api/client.ts';
import { GetUserSessionResponse, Member, MemberItems, Transaction } from '@/lib/members/member.ts';
import { UpdateMemberProfileFormValues } from '@/lib/members/components/profile-form.tsx';

/**
 * Add Contracts (Registration form)
 */
export const addMember = async (
  values: Omit<Member, 'memberStatus' | 'MemberId' | 'UserName'> & {
    clubId: string;
    // Password: string;
  },
) => {
  const response: AxiosResponse = await axiosInstance.post('/members', {
    ...values,
  });
  return response.data;
};

/**
 * Update Contracts
 */
export const updateMember = async (
  memberId: string,
  values: Omit<UpdateMemberProfileFormValues, 'ConfirmPassword'>,
) => {
  const response: AxiosResponse = await axiosInstance.put(`/members/${memberId}`, {
    ...values,
  });
  return response.data;
};

/**
 * Get the current user.
 */
export const getUser = async (): Promise<Member> => {
  const response = await axiosInstance.get<Member>('/members/me');
  return response.data;
};

/**
 * Get the current user membership data
 */
export const getMemberItems = async (): Promise<MemberItems> => {
  const response = await axiosInstance.get<MemberItems>('/members/items');
  return response.data;
};

/**
 * Get the current user membership transactions
 */
export const getMemberTransactions = async (): Promise<Transaction[]> => {
  const { data } = await axiosInstance.get<Transaction[]>('/members/transactions');
  return data;
};

/**
 * Unsubscribe from the current user membership
 */
export const unsubscribeMember = async () => {
  const response: AxiosResponse = await axiosInstance.delete('/members');
  return response.data;
};

/**
 * Get user session ID
 */
export const getUserSessionId = async (): Promise<string> => {
  const response = await axiosInstance.get<GetUserSessionResponse>('/members/session');
  return response.data.userSessionId;
};
